import { BackNavigationMode } from '@portal/wen-embed-api';
import Navigo from 'navigo';
import { DEMO_CONSTANTS } from './constants';

type DomValueType = string | HTMLElement;
type PrintableValueType = any;
type CreateButtonCbType = (button: HTMLButtonElement) => any;

const setInnerHtml = <E extends HTMLElement>(key: string, value: DomValueType, el: E) => {
  if (typeof value === 'string') {
    el.innerHTML = `
      <td>${key}</td>
      <td>${value}</td>
    `;
  } else {
    el.innerHTML = `
      <td>${key}</td>
      <td><span></span></td>
    `;
    el.querySelector('span').appendChild(value);
  }
  return el;
};


const printableValue = (value: PrintableValueType) => {
  if (typeof value === 'string') {
    return value;
  }
  return JSON.stringify(value);
};

const createButton = (label: string, callback: CreateButtonCbType) => {
  const button = document.createElement('button');
  button.innerHTML = label;
  button.onclick = async () => callback(button);
  return button;
};

const addTableRow = (key: string, value: DomValueType) => {
  let el = document.createElement('tr');
  el.id = key.replace(' ', '').toLowerCase();
  el = setInnerHtml(key, value, el);
  const tableData = document.querySelector('#table-data');
  tableData.appendChild(el);
};

const tableUtils = {
  addTableRowText: (key: string, value: PrintableValueType) => {
    addTableRow(key, printableValue(value));
  },
  addTableRowButton: (key: string, label: string, callback: CreateButtonCbType) => {
    const buttonEl = createButton(label, callback)
    addTableRow(key, buttonEl);
    return buttonEl;
  },
  updateTableRow: (key: string, value: DomValueType) => {
    const id = key.replace(' ', '').toLowerCase();
    let el = document.getElementById(id);
    el = setInnerHtml(key, value, el);
  }
};

const pageUtils = {
  updatePageTitle: (title: string) => {
    const titleEl = document.getElementById('page-title');
    titleEl.innerText = 'On page: ' + title;
  }
};

const router = new Navigo(DEMO_CONSTANTS.URL_PREFIX);
const routerUtils = {
  init: () => {
    router.on('/', () => {
      pageUtils.updatePageTitle('start')
    });
    router.on('/cats', () => {
      pageUtils.updatePageTitle('cats 😻')
    });
    router.on('/dogs', () => {
      pageUtils.updatePageTitle('dogs 🐶')
    });
    router.on('/intercepted', () => {
      pageUtils.updatePageTitle('intercepted 🤪')
    });
    router.resolve();
  },
  navigate: (url) => {
    router.navigate(url, { updateBrowserURL: true })
  }
};

const storageUtils = {
  getNavMode: () => {
    return localStorage.getItem('demonavmode') as BackNavigationMode;
  },
  getNavModeOpposite: (): BackNavigationMode => {
    return localStorage.getItem('demonavmode') === 'COMPRESSED' ? 'LINEAR' : 'COMPRESSED';
  },
  setNavMode: (mode: BackNavigationMode) => {
    return localStorage.setItem('demonavmode', mode);
  },
};

export { pageUtils, printableValue, routerUtils, storageUtils, tableUtils };

