import { proxy } from 'comlink';
import { BackNavigationMode } from './api-parts/navigation/navigation-api';
import { trackUrlChanges } from './api-parts/navigation/url-change-tracker';
import { ComlinkWrapper } from './comlink-utils/comlink-wrapper';
import { waitUntilContentReady } from './comlink-utils/dom-utils';
import { WenWebApiInternal } from './types/private-types';
import { WenWebApi } from './types/public-types';

export interface ConnectParams {
  timeoutMs?: number;
  backNavigationMode?: BackNavigationMode;
}

export const connect = async (connectParams: ConnectParams = {}) => {
  await waitUntilContentReady();
  const defaultParams: ConnectParams = {
    timeoutMs: 3000,
    backNavigationMode: 'LINEAR',
  };
  const params = { ...defaultParams, ...connectParams };
  const { timeoutMs } = params;
  const needsTimeout = timeoutMs >= 0;
  const apiObject = new Promise(async (resolve, reject) => {
    const connectTimeout = needsTimeout && setTimeout(() => {
      reject(new Error('Error initializing API failed due to timeout'));
    }, timeoutMs);
    const remoteApi = ComlinkWrapper.setupRemote() as unknown as WenWebApiInternal;
    await remoteApi.connectInternal(params);
    if (needsTimeout) {
      clearTimeout(connectTimeout);
    }
    trackUrlChanges((url) => {
      remoteApi.Navigation.urlChanged(url);
    });
    resolve((remoteApi));
  });
  return apiObject as Promise<WenWebApi>;
};

export const apiCallback = <F>(cb: F) => {
  return proxy(cb);
};
