import { windowEndpoint, wrap } from 'comlink';

export const ComlinkWrapper = {
  setupRemote: (): ReturnType<typeof wrap> => {
    if (window.parent === window) {
      throw new Error('Error initializing API');
    }
    return wrap(windowEndpoint(window.parent));
  }
};
