import { apiCallback, connect } from '@portal/wen-embed-api';
import { DEMO_CONSTANTS } from './constants';
import { printableValue, routerUtils, storageUtils, tableUtils } from './ui';

routerUtils.init()

const startApp = async () => {
  try {
    const backNavigationMode = storageUtils.getNavMode();
    const wenApi = await connect({ backNavigationMode });
    wenApi.Navigation.setNavigationInterceptor(apiCallback(() => {
      return new Promise((resolve) => {
        if (location.href.includes('intercepted')) {
          const value = confirm('Navigation intercepted! Are you sure you want to leave this page?')
          if (!value) {
            resolve(false);
          }
        }
        resolve(true);
      });
    }));
    // General things
    tableUtils.addTableRowText('Connection', 'successful');
    const isSsoDialogEnabled = await wenApi.Auth.isSsoDialogEnabled();
    tableUtils.addTableRowText('isSsoDialogEnabled', isSsoDialogEnabled);
    // Auth config
    const authConfig = await wenApi.Auth.getAuthConfig();
    tableUtils.addTableRowText('Auth config', authConfig);
    // User info
    const userInfo = await wenApi.Auth.getUserInfo();
    tableUtils.addTableRowText('User id', userInfo.userId);
    tableUtils.addTableRowText('Is anonymous?', userInfo.isAnonymous);
    // Navigation
    tableUtils.addTableRowButton('Navigation', 'To start page', () => routerUtils.navigate('/'));
    tableUtils.addTableRowButton('', 'To dogs page', () => routerUtils.navigate('dogs'));
    tableUtils.addTableRowButton('', 'To cats page', () => routerUtils.navigate('cats'));
    tableUtils.addTableRowButton('', 'To intercepted page', () => routerUtils.navigate('intercepted'));
    const createButtonLabel = () => {
      const mode = storageUtils.getNavModeOpposite();
      return `Set navmode to: ${mode}`;
    };
    tableUtils.addTableRowButton('', createButtonLabel(), (buttonEl) => {
      storageUtils.setNavMode(storageUtils.getNavModeOpposite());
      buttonEl.disabled = true;
      alert('Navigation mode changed, please restart embedded app!');
    });
    tableUtils.addTableRowButton('Exit app', 'GET ME OUT HERE', () => wenApi.Navigation.exitApp());
    // Login
    const loginButt = tableUtils.addTableRowButton('Code', 'Try me!', async () => {
      try {
        const code = await wenApi.Auth.tryAuth({ clientId: DEMO_CONSTANTS.WEB_FRAMEWORK_TEST_CLIENT_ID });
        tableUtils.updateTableRow('Code result', printableValue(code));
        loginButt.disabled = true;
      } catch (err) {
        tableUtils.updateTableRow('Code result', printableValue(err));
      }
    });
    tableUtils.addTableRowText('Code result', printableValue('...'));
  } catch (e) {
    alert('Error: ' + e.message);
  }
};

setTimeout(() => {
  startApp();
}, 1000);
