export const waitUntilContentReady = () => {
  const delay = new Promise((resolve) => {
    if (document.readyState === 'complete') {
      setTimeout(() => {
        resolve(null);
      }, 0);
    } else {
      window.addEventListener('load', () => {
        setTimeout(() => {
          resolve(null);
        }, 0);
      }, { once: true });
    }
  });
  return delay;
};
